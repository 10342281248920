<template>
  <p
    @click="onCheck({ $event })"
    class="d-flex flex-wrap justify-content-start"
  >
    <template v-if="status === 1 && role === roles.florist">
      <template v-if="id === florist_id">
        <b-form-checkbox
          v-model='checked_florist'
          v-bind:class="{'text-success': role === roles.florist }"
        >
          <p v-bind:class="{'text-success': role === roles.florist }"> {{ florist_full_name }} </p>
        </b-form-checkbox>
      </template>
      <template v-else-if="!florist_id">
        <b-form-checkbox
          v-model='checked_florist'
          v-bind:class="{'text-success': role === roles.florist }"
        >
        </b-form-checkbox>
      </template>
      <template v-else>
        <template>
          <p class="text-left text-success"> {{ florist_full_name }} </p>
        </template>
      </template>
    </template>

    <template v-else>
      <template>
        <p class="text-left text-success"> {{ florist_full_name }} </p>
      </template>
    </template>

  </p>
</template>

<script>
import { RoleCollection } from '../../store/modules/auth/types'

export default {
  name: 'CalendarOrderChecker',
  props: {
    id: {
      type: Number,
      default() {
        return 0
      },
    },
    manager_full_name: {
      type: String,
      require: true,
    },
    florist_full_name: {
      type: String,
      default() {
        return ''
      },
    },
    courier_full_name: {
      type: String,
      default() {
        return ''
      },
    },
    role: {
      type: String,
      require: true,
    },
    manager_id: {
      type: Number,
      require: true,
    },
    florist_id: {
      type: Number,
      default() {
        return 0
      },
    },
    status: {
      type: Number,
      require: true,
    },
    full_name: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      checked_florist: this.florist_id ? this.florist_id === this.id : false,
      checked_manager: false,
    }
  },
  updated() {
    this.checked_florist = this.id === this.florist_id
  },
  methods: {
    onCheck({ $event }) {
      const { detail } = $event
      $event.stopPropagation()
      if (!detail || this.status !== 1) return

      if (this.manager_id === this.id) {
        this.$emit('check', { manager_id: this.checked_manager ? this.id : 0 })
        return
      }

      if (this.florist_id === this.id) {
        this.$emit('check', { florist_id: 0 })
        return
      }

      if (!this.florist_id) {
        this.$emit('check', { florist_id: this.id })
        return
      }
    },
  },
  computed: {
    roles() {
      return RoleCollection
    },
  },
}
</script>
